import { Fragment } from "react";
import {
    NavLink
} from "react-router-dom";
import './Menu.css';

var ReactGA = require('react-ga');

export const Menu = (props) => {
    return (
        <Fragment>
            <nav className={`Menu ${props.open ? "MenuOpen" : "MenuCollapsed"} md:max-w-sm`}>
                <NavLink
                    to='/2021'
                    activeClassName="activePage"
                    onClick={() => props.setOpen()}
                >
                    <span>2021</span>
                </NavLink>
                <NavLink
                    to='/2020'
                    activeClassName="activePage"
                    onClick={() => props.setOpen()}
                >
                    <span>2020</span>
                </NavLink>
                <ReactGA.OutboundLink
                    eventLabel="https://blessedhopelexington.com/"
                    to="https://blessedhopelexington.com/"
                    target="_blank"
                >
                    Blessed Hope Baptist Church
                </ReactGA.OutboundLink>
            </nav>
        </Fragment>
    )
}