import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';

import { MainPage } from './components/MainPage';
import { Header } from './components/Header/Header';
// import { useTracking } from './tracking'

import './App.css';

function App() {
  // useTracking('G-DG7XP4M6HX');
  const [ year, setYear ] = useState();

  const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  history.listen(location => {
    const options = {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    };
    ReactGA.initialize('G-DG7XP4M6HX', options);
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <Router>
      <Header year={year}/>

      <Switch>

        <Route history={history} path="/:year">
          <MainPage setYear={(e) => setYear(e)} />
        </Route>

        <Route history={history} path="*">
          <div className="flex justify-center my-5">Choose the season in the menu above.</div>
        </Route>

      </Switch>

    </Router>
  );
}

export default App;
