import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Schedule } from "../Schedule/Schedule";

const title = "Kentucky Basketball Schedule";

export const MainPage = (props) => {
    const { year } = useParams();
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const fetchData = async (season) => {
      const response =  await fetch(`../../data/${season}.json`);
      const json = await response.json();
      setGames(json.games);
    }

    useEffect(() => {
      setLoading(true);
      document.title = `${year} ${title}`;
      props.setYear(year);
      fetchData(year)
        .catch((error) => {
          setGames([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [props, year]);

    return (
        <Schedule
          games={games}
          loading={loading}
        />
    );
}