import './Event.css';

export const Event = (props) => {
    const dateOptions = {
        // hourCycle: 'h12',
        // dayPeriod: "short",
        month: "short",
        // weekday: "short",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // timeZoneName: "short"
    };
    const dateTimeOptions = {
        hourCycle: 'h12',
        // dayPeriod: "short",
        month: "short",
        // weekday: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        // timeZoneName: "short"
    };

    const renderDate = (date) => {
        if (date.endsWith("TBA") || date.endsWith("tba")) {
            return new Date(date.substr(0, date.length - 3)).toLocaleDateString('en-US', dateOptions) + ", TBA";
        }
        else {
            return new Date(date).toLocaleDateString('en-US', dateTimeOptions);
        }
    }

    return (
        <div className="Event shadow-md h-16 min-w-full">
            <div className="EventData w-32 md:w-48">
                {renderDate(props.game.date)}
            </div>
            <div className="EventData w-28 md:w-44">
                {props.game.opponent}
            </div>
            <div className="EventData w-28 md:w-44">
                {props.game.location}
            </div>
            <div className="EventData w-28 md:w-44">
                {props.game.channel ? props.game.channel : ""}
            </div>
        </div>
    );
}